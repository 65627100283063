.quick-actions {
    overflow-x: auto;
    text-align: start;
    border-radius: 3px;
    font-size: 0.9rem;
    /* font-weight: 700; */
    -webkit-user-select: none;  
	-moz-user-select: none;    
	-ms-user-select: none;      
	user-select: none;
}

.quick-actions button,
.quick-actions a {
    white-space: nowrap;
}

.quick-actions svg {
    margin-bottom: 2px;
}

@media only screen and (max-width: 992px) {
    .quick-actions a,
    .quick-actions span {
        padding: 6px 8px;
        background: #f6f6f6;
        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }    
}

.scroll-shadows {
    background-image: 

    /* Shadows */ 
    linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),

    /* Shadow covers */ 
    linear-gradient(to right, rgba(0,0,0,.03), rgba(255,255,255,0)),
    linear-gradient(to left, rgba(0,0,0,.03), rgba(255,255,255,0));   

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;

    background-size: 30px 100%, 30px 100%, 30px 100%, 30px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

