.editable-text-field input {
    display:inline-block;
    box-sizing: border-box;
    border: 1px #e3e3e3 solid;
    width: 100%;
    max-width: 400px;
    /*font-size:0.85rem;*/
}

.editable-text-field:first-child {
    display:inline-block;
}

.editable-text-field .easy-edit-instructions {
    font-size: 0.85rem;
    font-weight: normal;
    color: #777;
    position: absolute;

}

@media only screen and (max-width: 992px) {
    .editable-text-field .easy-edit-instructions {
        position: relative;
    }
}