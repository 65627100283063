.box {
    background: #fff; 
    border-radius: 10px; 
    /* margin: 1rem 0.5rem; */
    border: 1px #e3e3e3 solid;
    padding: 1rem;
    /* overflow: auto; */
    /* font-size:1.6rem;
    line-height: 1.6; */
}

@media only screen and (max-width: 992px) {
    .box {

        /* margin: 0; */
        /*padding:0.5rem;*/

        /*font-size:1rem;
        line-height: 1.5;*/
    }
}