.progress-bar-class {
    overflow: hidden;
    color: #fff !important;
    font-size: 0.6rem;
    border-radius: 5px;
}

.progress-bar-class .progress-bar {
    overflow: initial;
    padding-left: 5px;
}