#page-content-wrapper {
    min-width: 0;
    /* width: 100%; */
    transition: .25s;
    background: white;
    padding-top: 10px;

}

@media only screen and (min-width: 992px) {
    .sidebar-offset {
        margin-left: 200px;
    }
}

