:root {
	--first-color: #ff6c00;	
	--first-color-d1 : #e05e00;
	--first-color-d2 : #7a3300;
	--first-color-l1 : #ff8833;
	--first-color-l2 : #ffa05c;
	
	--second-color: #e89a1a;
	--black: #5c5c5c;
	--white: #f9f9f9;
	--green: #2ab92a;
	--blue: #387BD1;
	--bg-tone: #f7f7f7;
	--bg-lighter: #f9f9f9;

	--bs-primary: rgb(56, 123, 209);
}

body {
	/* background: var(--bg-tone) */
	/* background: rgb(249,249,249);
	background: -moz-radial-gradient(circle, rgba(249,249,249,1) 16%, rgba(255,255,255,1) 100%);
	background: -webkit-radial-gradient(circle, rgba(249,249,249,1) 16%, rgba(255,255,255,1) 100%);
	background: radial-gradient(circle, rgba(249,249,249,1) 16%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9f9f9",endColorstr="#ffffff",GradientType=1); */
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

a {
	text-decoration: none;
}

a:hover, .link-primary:hover {
	/* text-shadow: 0px 0px 30px #0087ff; */

}

.link-primary {
	cursor: pointer;
}

.gradient-text {
	/* display:inline-block; */
	background: -webkit-linear-gradient(right, var(--first-color), var(--second-color));
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: var(--first-color);
}

.primary-gradient-text {
	display:inline-block;
	background: -webkit-linear-gradient(right, var(--first-color-l2), var(--first-color));
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: var(--first-color);
}

.gradient-background {
	background: rgb(253,253,253);
	background: -moz-radial-gradient(circle, rgba(253,253,253,1) 14%, rgba(247,247,247,1) 100%);
	background: -webkit-radial-gradient(circle, rgba(253,253,253,1) 14%, rgba(247,247,247,1) 100%);
	background: radial-gradient(circle, rgba(253,253,253,1) 14%, rgba(247,247,247,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdfdfd",endColorstr="#f7f7f7",GradientType=1); 
}

.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

html { font-size: calc(1em + 0.2vw); }

@media only screen and (max-width: 992px) {
    .mobile-no-margin {
        margin: 0;
    }
    .mobile-no-padding {
        padding: 0;
    }
    .mobile-no-x-padding {
        padding-left: 0;
		padding-right: 0;
    }
    .mobile-no-x-margin {
        margin-left: 0;
		margin-right: 0;
    }
}

.make-blur-anim {
	animation: blur-anim 3s linear forwards;
}

@keyframes blur-anim {
	100% {	filter: blur(20px) };
}

.desaturate {
	animation: desaturate-anim 3s linear forwards;
}

@keyframes desaturate-anim {
	100% {	filter: grayscale(70%); };
}

.hide-dropdown-arrow .dropdown-toggle::after {
	display: none !important; 
  }

.child-button-p-0 button {
	padding:0;
}

.no-bottom-border {
	border-bottom: 0;
}

.rounded-corners {
	border-radius: 15px;
}

.strong-underline {
	box-shadow: 0px 2px var(--first-color-l2);
}

.dotted-border {
	border: 2px var(--first-color) solid;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	border-style: dotted;
}

.nav-tabs .nav-link {
	/* border: 2px solid #eee !important; */
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	/* border-width: 0 2px 2px 0 !important; */
	color: #9a9a9a !important;
	font-weight: 400;
}

.nav-tabs .active {
	color: #5c5c5c !important;
	box-sizing: border-box;
	border-bottom: 2px solid #ff6c00 !important;
	background: #fff !important;
}

.icon-hover:hover {
	background: #cdcdcd;
	padding: 3px;
	border-radius: 50%;
	transition: 0.25s;
}

.settings-container-wrapper {
	display: block;
	max-width: 100%;
	overflow: auto;
}
.settings-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.settings-label {
	white-space: nowrap;
	color: #000;
	font-size:0.9rem;

}

.settings-item {
	padding: 5px;
	flex-grow: 1;
	border: 2px #efefef solid;
	text-align: left;
	border-radius: 6px;
}

.settings-value {
	color: var(--first-color);
}

.settings-item-md-full {
	flex-grow: 0;
}

.top-profile-balance {
	color: #555;
	font-size: 0.8em
}

th {
	-webkit-user-select: none;  
	-moz-user-select: none;    
	-ms-user-select: none;      
	user-select: none;
}

.pointer {
	cursor: pointer;
}
@media only screen and (max-width: 992px) {
	.settings-container {
		gap: 5px;
	}
    .settings-item {
		/* flex-grow: 0; */
		text-align: left;
    }
	.settings-item-md-full {
		flex-grow: 1;
		text-align: center;
	}

	.top-profile-balance {
		position: absolute;
		padding-top: 2px;
		right: 44px;
	}
}

.light-border {
	border: 2px #efefef solid;
	border-radius: 4px;
}

.timeframe-button {
	/* border: 2px solid #65a3ff;
	background: var(--bg-lighter);
	border-radius: 6px;
	padding: 4px 12px; */
	color: #2376f1;
	cursor: pointer;
	align-content: center;
}

.timeframe-button:hover {
	/* background: #65a3ff;
	color: #fff; */
}

@media only screen and (max-width: 992px) {
	.timeframe-button {
		width: 100%;
		border: none;
		text-align: left;
		background: none;
		/* padding-left: 0.5em; */
	}
}

.black {
	color: var(--black);
}

.white {
	color: var(--white);
}

.blue {
	color: var(--blue);
}

.green {
	color: var(--green);
}

.first-color {
	color: var(--first-color);
}

li::marker {
	color: var(--first-color);
}

.disable-text-selection {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

@media only screen and (max-width: 992px) {
	.desktop-only {display:none}
}

@media only screen and (min-width: 992px) {
	.mobile-only {display:none}
}