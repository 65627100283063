.value-component {
    display: inline-block;
    transition: opacity 0.3s ease-in-out;
}

.value-loading {
    opacity: 0.5; /* Faded loading state */
}

.value-loaded {
    opacity: 1; /* Fully visible loaded state */
}

.value-container {
    
    
}

.value-big-font {
    font-size: 1em; /* Customize size as needed */
    font-weight: inherit;
}

.value-small-font {
    font-size: 0.7em; /* Smaller font for decimals */
    font-weight: normal;
    color: #595959;
}

.value-placeholder {
    color: gray; /* Color for loading placeholder */
    font-style: italic;
}