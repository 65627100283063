.date-picker-class {
    margin: 0.5em;
}

@media only screen and (max-width: 556px) {
    .date-picker-class {
        scale: 0.9;
        margin: 0;
        
    }
}