#campaignsTable tr td:last-child {
    width: 1%;
    white-space: nowrap;
}

#campaignsTable {
  font-size: 0.9rem;
  /* background: var(--bg-lighter); */
}

#campaignsTable th {
	text-align: center;
	color: #454545;
  /* background: var(--bg-lighter); */
  font-weight: 400;
}

#campaignsTable td {
  /* background: var(--bg-lighter); */
}

#campaignsTable table thead th {
    padding: 3px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    /* background: var(--bg-lighter); */
  }
#campaignsTable table tbody td {
  /* background: var(--bg-lighter); */
  padding: 4px 5px;
  text-align: center;
}

#campaignsTable table tbody th {
  font-weight: 100;
  font-style: italic;
  text-align: left;
  position: relative;
}
#campaignsTable table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
#campaignsTable table tbody th {
  position: sticky;
  left: 0;
  background: var(--bg-lighter);
  z-index: 1;
}


@media only screen and (min-width: 992px) {
	#campaignsTable {
		table-layout: fixed;
	}
}