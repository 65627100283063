#dataTable tr td:last-child {
    width: 1%;
    white-space: nowrap;
}

#dataTable {
  font-size: 0.9rem;
}

#dataTable th {
	text-align: center;
	color: #454545;
}

#dataTable table thead th {
    padding: 3px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    background: white;
  }
  #dataTable table tbody td {
    background: #fff;
    padding: 4px 5px;
    text-align: center;
  }
  
  #dataTable table tbody th {
    font-weight: 100;
    font-style: italic;
    text-align: left;
    position: relative;
  }
  #dataTable table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  #dataTable table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
