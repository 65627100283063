@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');

body {
	margin: 0;
	font-family: "Poppins", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}