#sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -200px;
    min-height: 100vh !important;
    z-index: 100;
    padding: 0; /* was 10px */
    /* background: #f7f7f7; */
    background: #fff;
    background: var(--bg-tone);
    color: #444;
    width: 200px;
    text-align: start;
    overflow-y: auto;
    border-right: 1px #e3e3e3 solid;
    transition: .25s;
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;

}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

/* 
@media only screen and (max-width: 992px) {
    #page-content-wrapper, #topbar-wrapper {
        margin-left: auto;
        width: 100%;
    }
    #sidebar {
        display: none;
    }
} */


.sidebar-item {
    display: block;
    padding: 6px 10px 6px 15px;
    font-size: 0.9rem;
    font-weight: 400;
    color: #444;
    text-decoration: none;
    background: 0 0;
    transition: color .15s ease-in-out,background-color .25s ease-in-out,border-color .15s ease-in-out;
    background: #f7f7f7;
}

.sidebar-subitem {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75em;
    color: #444;
    padding-left: 40px;
    white-space: nowrap;
}

.active-sidebar-subitem {
    color: #272727;
    background: #e5e5e5;
    /* border-right: 2px var(--first-color-l1) solid; */
    
}

.sidebar-item-icon {
    margin-bottom: 3px;
}

.sidebar-link:hover  {
    background: #eaeaea !important;
}

.active-sidebar-item {
    /* color: var(--first-color); */
    background: #eaeaea !important;
    border-left: 6px var(--first-color) solid !important;

}

#sidebar h5 {
    font-size: 0.8rem;
    border:0;
    margin-top: 10px;
    margin-bottom:0;
    color: #454545;
    font-weight: bold;
    padding-left: 10px;
}

#sidebar .accordion-button {
    background-color: transparent !important;
    padding: 5px 10px 5px 15px;
    border: 0;
    font-size: 0.9rem;
    box-shadow: none;
}
  
  #sidebar .accordion-body {
    padding: 0px;
    background: #f3f3f3;
    margin-bottom: 10px;
    font-size:0.9rem;
}

#sidebar .accordion-header {
    padding: 0;
    border: 0;
    font-size: 0.9rem;
}
#sidebar .accordion-button:focus {
    box-shadow: none;
}
  
#sidebar .accordion-button:not(.collapsed) {
    color: #212529;
}

.show-sidebar {
    left: 0 !important;
}



