.loader-wrapper {
	display: block;
	text-align: center;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.loader-label {
	color: #d2d2d2;
	font-size: 0.7rem;
}

.loader {
	height: 4.5px;
	width: 145.6px;
	background: linear-gradient(#e3e3e3 0 0),
		linear-gradient(#e3e3e3 0),
		#f3f3f3;
	background-size: 60% 100%;
	background-repeat: no-repeat;
	animation: loader-7x9cg2 3s infinite;
 }
 
 @keyframes loader-7x9cg2 {
	0% {
	   background-position: -150% 0,-150% 0;
	}
 
	66% {
	   background-position: 250% 0,-150% 0;
	}
 
	100% {
	   background-position: 250% 0, 250% 0;
	}
 }