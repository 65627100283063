.text-input-description {
    display: inline-block;
}

.text-input input {
    border: 0;
    border-bottom: 2px #387bd1 solid;
    border-radius: 5px 5px 0px 0px;
}

.text-input input:focus {
    border-color: #387bd1;
    box-shadow: none;
}

.text-input .invalid-highlight {
	border-bottom: 2px red dashed !important;
}

.text-input input{
  padding-left: 15px;
  padding-top: 2rem !important;
  position:relative;
}

.text-input input:after{
    position: absolute;
    left: 6px;
    top: 2px;
    content: '$';
}