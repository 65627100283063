/* .active-navlink {
    background: #e4e4e4;
    border-radius: 5px;
    box-shadow: 2px 2px 3px #000;
    font-weight: bold;
} */

#topbar {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    padding-left: 0.5rem
}

#topbar-wrapper {
    transition: .25s;
    /* background: var(--bg-tone); */
    position: fixed;
    left: 0;
    right: 0;
    background: white;
    z-index:99;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);

}  


.active-navlink {
    position: relative;
    color: #5c5c5c;
    background: #F7F7F7;
}

.active-navlink a {
   border-bottom: 2px var(--first-color-l1) solid;
    margin-bottom: -2px;
}

.nav-link {
    color: inherit !important;
}

.navbar-toggler {
    border: 0;
    outline: 0;
    box-shadow: none !important;
}

.nav-breadcrumbs {
    font-size: 1rem; 
    color: rgb(156, 156, 156);
    margin-bottom: 0;
    text-align: center;
    padding-right: 1rem;
    font-weight: 300;
}

.nav-breadcrumb:first-child {
    color: var(--black);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1.4px;
    font-size: 1rem;
}

.nav-breadcrumb {
    color: #919191;
    white-space: nowrap;
    display: inline-block;
}

@media only screen and (max-width: 992px) {
    #topbar .logo-class {
        display: inline-block;
    }

    .nav-breadcrumbs {
        width: 100%;
    }

    #topbar-wrapper {
        /* background: #f9f9f9; */
    }

}

@media only screen and (min-width: 992px) {
    #topbar .logo-class {
        display: none;
    }

    #topbar { 
        border: 0;
        box-shadow: none;
        border-bottom: 1px #e3e3e3 solid;
    }
}