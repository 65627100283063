.offcanvas-end {
    max-width: 80% !important;
    width: 600px !important;
    overflow: hidden;

    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.offcanvas-header {
    border-bottom: 2px #eeeeee solid;
}

