.stat-widget {
    position:relative;
    flex: 1;
    text-align: left;
    padding-bottom: 0.2em;
    padding: 0.5rem;
    background: white;
    border: 2px #f0f0f0 solid;
    border-radius: 10px;
    color: #7B7B7B;
    word-wrap: break-word;
    cursor: pointer;
}

.stat-widget .stat-value {
    margin-bottom: 0;
    font-size: 1.1rem;

}

.stat-widget-link {
    font-size: 0.8rem;
    color: var(--bs-primary);
    float: right;
    margin-top: 3px;
}

.stat-widget-highlighted {
    border-color: var(--first-color-l2);
}

@media only screen and (max-width: 992px) {
    .stat-widget {
        font-size: 14px;
        border: none;
        text-align: left;
        color: #171717;
        padding-left: 0;
        padding-right: 0;
        
    }

    .stat-widget .stat-value {
        font-size: 1.5rem;
        font-weight: 300;
    }

    .stat-widget-link {
        float: none;
    }
}