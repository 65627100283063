.status-badge {
    text-transform: capitalize;
    border: 2px #eee solid;
    border-radius: 5px;
    font-size: 0.9rem;
    padding: 3px 6px;
    cursor: default;
    display: flex;
    align-items: center;
    gap: 5px;
    display: inline-block;
    
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
}

.status-badge-smaller {
    text-transform: capitalize;
    border: 2px #eee solid;
    border-radius: 5px;
    font-size: 0.8em;
    font-weight: bold;
    padding: 2px 4px;
    cursor: default;  
}

.status-badge-small {
    border: 1px #eee solid;
    border-radius: 4px;
    font-size: 0.7em;
    padding: 2px 4px;
    cursor: default;  
}

.status-badge svg {
    vertical-align: initial;
}