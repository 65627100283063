.select-list .select__control {
    padding: 12px 5px 14px;
    border: 0;
    border-bottom: 2px #387bd1 solid;
    border-radius: 5px 5px 0px 0px;
}

.select-list .select__control {
    border-color: #387bd1;
    box-shadow: none;
}

.invalid-field-highlight {
	border-bottom: 2px red dashed !important;
}